// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  margin-bottom: 4em;
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: #303030;
}
.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}


// Related posts
.related {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #eee;
}

.related-posts {
  padding-left: 0;
  list-style: none;

  h3 {
    margin-top: 0;
  }

  li {
    small {
      font-size: 75%;
      color: #999;
    }

    a:hover {
      color: #268bd2;
      text-decoration: none;

      small {
        color: inherit;
      }
    }
  }
}
